import React from "react";
import Sidebar from "../component/Sidebar";
import Contact from "./Contact";

const index = () => {
  return (
    <>
      <Sidebar />
      <Contact />
    </>
  );
};

export default index;
