import React from "react";
import Sidebar from "../component/Sidebar";
import Inquiry from "./Inquiry";

const index = () => {
  return (
    <>
      <Sidebar />
      <Inquiry />
    </>
  );
};

export default index;
