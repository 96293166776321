import React from "react";
import Sidebar from "../component/Sidebar";
import Category from "./Category";

const index = () => {
  return (
    <>
      <Sidebar />
      <Category />
    </>
  );
};

export default index;
